@import "./steppped";
.header-padding-top {
  padding-top: 10.5rem;
  @media screen and (max-width: 1400px) {
    padding-top: 10.2rem;
  }
}
.filtersWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 550px) {
    gap: 1rem;
  }
}
.filtersCategory {
  font-size: 1.6rem;
  cursor: pointer;
  @media screen and (max-width: 550px) {
    font-size: 1.4rem !important;
    text-wrap: nowrap;
    height: 3rem;
  }
}
.filtersCategory:after {
  content: "\00d7";
  font-size: 2em;
  margin: 0 6px;
  vertical-align: middle;
  line-height: 2.4em;
  font-weight: 200;
}
.filtersCategory:hover {
  text-decoration: underline;
}
.clearFilters {
  font-size: 1.6em;
  cursor: pointer;
  text-decoration: underline;
  font-family: Montserrat;
  @media screen and (max-width: 550px) {
    margin-top: 3rem;
  }
}
.products-header {
  border-bottom: $lightGrayBorder;
  .filters-row {
    display: flex;
    flex-direction: row;
    .filters-wrap {
      display: flex;
      align-items: center;
    }
    .rightQTY {
      @media (max-width: 550px) {
        width: 100% !important;
      }
    }
    .made-in-usa {
      // right: 0 !important;
      left: auto !important;
      transform: translate3d(0, 84px, 0px) !important;
    }
    .baba-wrapper {
      padding: 1rem 4rem;
    }
    .baba-description {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      color: #000;
    }
    .baba-link {
      text-decoration: underline;
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 2rem;
    }

    @media (max-width: 992px) {
      flex-direction: column;
    }
    @media (max-width: 640px) {
      .col {
        display: grid;
        > div {
          margin-left: 0 !important;
          a {
            padding: 1.5rem !important;
            margin-left: 0 !important;
          }
        }
        .baba-link {
          padding-left: 0 !important;
        }
      }

      .made-in-usa {
        top: -3rem !important;
      }
    }
  }
  .md-form {
    margin: 0;
    input {
      margin-bottom: 0;
    }
  }
  .pagination {
    margin-left: 5rem;
    padding-left: 5rem;
    border-left: $lightGrayBorder;
  }
}

.pagination-container {
  padding-bottom: 3rem;
  padding-top: 3rem;
  .dropdown-content {
    z-index: 99 !important;
  }
  .pagination-dropdown {
    label {
      background: white !important;
      font-size: 16px !important;
      line-height: 1.44;
    }

    .select-wrapper {
      ul {
        width: 14rem !important;
        max-width: 14rem !important;
        margin-top: 3.6rem;
      }
      input {
        margin-top: 1px;
        font-size: 1.6rem !important;
        margin-left: -1rem;
      }
    }
  }
}
.products-header-inner {
  .drop-down-sort {
    padding: 0;
    margin: 0;
    .select-wrapper {
      ul {
        max-width: 22rem !important;
        margin-top: 2.1rem;
      }
    }
  }
  .select-wob {
    label {
      font-size: 1.6rem;
      background: white !important;
      margin-bottom: 2px;
      margin-top: 0.7rem;
    }
    input {
      &.select-dropdown {
        font-size: 1.4rem !important;
        padding: 0 !important;
        max-width: 13rem;
        margin-bottom: -1px;
      }
    }
  }
}
.products-footer {
  padding: 8.5rem 0;
  .h6 {
    color: #595959;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-right: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .header-padding-top {
    padding-top: 10rem;
  }
}
@media (max-width: 992px) {
  .header-padding-top {
    padding-top: 7rem;
  }
  .products-header {
    .pagination {
      padding-left: 40%;
    }
    .products-header-inner {
      .drop-down-sort {
        .select-wrapper {
          ul {
            max-width: 22rem !important;
            margin-top: 0px;
          }
        }
      }

      .select-wob {
        margin-left: -77%;
        input {
          &.select-dropdown {
            padding: 0 !important;
            max-width: 13rem;
            margin-bottom: -1px;
          }
        }
      }
    }

    .main-title-products {
      border-bottom: $lightGrayBorder;
      .h4 {
        margin-left: 1.5rem;
        padding-top: 1.7rem;
        padding-bottom: 1.7rem;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.13;
        letter-spacing: 1px;
        color: #1e1b3b;
      }
    }
  }
  .pagination-container {
    border-left: none !important;
    padding-bottom: 0.9rem;
    padding-top: 1.5rem;
    margin-left: 0.5rem !important;
    text-align: left;
    .pagination-dropdown {
      .select-wrapper {
        label {
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 765px) {
  .products-header .products-header-inner .select-wob {
    margin-left: -88%;
  }
}

@media (max-width: 575px) {
  .header-padding-top {
    h4 {
      font-size: 2rem;
    }
  }
  .landing-wrapper {
    h4 {
      font-size: 1.8rem;
    }
  }
  .products-header {
    .products-header-inner {
      .main-title-products {
        h4 {
          font-size: 2rem;
          line-height: 3.4rem;
          margin-left: 0;
        }
      }
      .drop-down-sort {
        display: flex;
        justify-content: start;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
    }
    .pagination {
      padding-left: 4.5rem;
    }
  }
  .paginate-mobile {
    display: flex;
    justify-content: center;
  }
  .pagination-container {
    padding-bottom: 1.7rem;
    padding-top: 1.7rem;
    .page-number-container {
      p {
        font-size: 1.2rem;
      }
      ul {
        padding-top: 3.5rem !important;
        li {
          font-size: 1.2rem;
        }
      }
    }
    .pagination-dropdown {
      .select-wrapper {
        ul {
          margin-top: -10px;
        }
        input {
          font-size: 12px !important;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.75px;
          color: #151522;
        }
        label {
          font-size: 12px !important;
          font-weight: 500;
          font-stretch: normal;
          font-style: bold;
          line-height: normal;
          letter-spacing: 0.75px;
          color: #151522;
        }
      }
    }
  }

  .products-header-inner {
    .drop-down-sort {
      .select-wrapper {
        ul {
          margin-top: -0.9rem !important;
          li {
            span {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .select-wob {
      margin-left: 0 !important;
      label {
        font-size: 1.2rem !important;
        font-weight: 500;
        font-stretch: normal;
        font-style: bold;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #151522;
        margin-left: 24px !important;
        overflow: initial;
      }
      input {
        &.select-dropdown {
          padding: 0 !important;
          max-width: 13rem;
          margin-bottom: -1px;
          font-size: 12px !important;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.75px;
          color: #151522;
        }
      }
    }
  }
}
.products-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 3.5rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr 1fr;
  }
  @import "./listing/style";
}

@media (max-width: 400px) {
  .products-header {
    .pagination {
      padding-left: 20% !important;
    }
  }

  .products-header-inner {
    .select-wob {
      margin-left: 0 !important;
    }
  }
}

.no-results-page {
  h2 {
    font-family: SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    color: #15152a;
  }
  p {
    font-size: 1.6rem !important;
    font-family: SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif !important;
  }
  @media (max-width: 992px) {
    padding-top: 0 !important;
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 550px) {
  .show.dropdown-menu.rightQTY {
    //position:absolute!important;
    right: 0 !important;
    //  left:auto;
    width: 300%;
    // top:236px!important;
    transform: translate3d(-11%, 84px, 0px) !important;
  }
  .left-filter-availability {
    margin-right: 10px !important;
  }
}
.switch-wrapper {
  display: flex;
  align-items: center;
  padding: 1rem 3rem 1rem 0;
  font-size: 1.6rem;
}
.switch {
  display: inline-flex;
  align-items: center;
}

.basicsSwitch {
  padding-bottom: 0.2rem;
  .lever {
    width: 40px !important;
    height: 20px !important;
    background: transparent !important;
    border: 1px solid #12121c;
    border-radius: 20px;
    position: relative;
    display: inline-block;
  }
  .lever::after {
    content: "";
    width: 15px !important;
    height: 15px !important;
    background: #12121c !important;
    border-radius: 50%;
    position: absolute;
    top: 50% !important;
    left: 2px !important;
    transform: translateY(-50%);
    transition: 0.3s;
    margin: 0 !important;
  }

  input:checked + .lever:after {
    background-color: #f29823 !important;
    left: 21px !important;
  }

  label {
    font-size: 0 !important;
    color: #fff;
  }
}
